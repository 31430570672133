let backgroundCaptions_old = [
  "ABYSSES",
  "MAP OF LOST THINGS",
  "NEW SKY",
  "CONSTELLATION",
  "FLOATING",
  "MINIATURES",
  "THE IMPACT"
];

let backgroundCaptions = [
  "STRATA",
  "MEMORIES",
  "FLOW",
  "LINK",
  "OCEAN",
  "FRAGMENT",
  "LAYERS"
];

export default backgroundCaptions;
